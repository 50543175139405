/**
 *  Formulář žádosti o zařazení do vstupního vzdělávacího programu
 */
import React from "react";

import * as state from "../../../lib/state";

import WebRequest from "../../../components/templates/WebRequest";
import FormInput from "../../../components/controls/Forms/FormsInput";
import FormRadio from "../../../components/controls/Forms/FormRadio";
import FormCheckBox from "../../../components/controls/Forms/FormCheckBox";
import FormText from "../../../components/controls/FormText";
import Button from "../../../components/controls/Button";
import CheckBoxRadioFrame from "../../../components/controls/CheckBoxRadioFrame";
import FormFileUpload from "../../system/file/FormFileUpload";


interface WebRequestExamInVpRepeatedPageProps {
	id?: string;
}

function WebRequestExamInVpRepeatedPage(props: WebRequestExamInVpRepeatedPageProps) {
	const title = "ŽÁDOST O VYKONÁNÍ OPAKOVANÉ PROFESNÍ ZKOUŠKY V RÁMCI VSTUPNÍHO VZDĚLÁVACÍHO PROGRAMU";
	const { webRequestExamInVp: model } = state.useStateContext();
	const form = model.form;

	return (
		<WebRequest
			title={title}
			description=""
			authorized
			model={model.common}
			id={props.id}
		>
			<h2 className="form-title">Druh vstupního vzdělávacího programu</h2>
			<CheckBoxRadioFrame className="mb-2">
				<div className="row">
					<div className="col-md-6">
						<FormRadio form={form} field="program_type" option="vppzl" title="v praktickém zubním lékařství (VPPZL)" />
					</div>
					<div className="col-md-6">
						<FormRadio form={form} field="program_type" option="vpchir" title="ve stomatochirurgii (VPCHIR)" />
					</div>
					<div className="col-md-6">
						<FormRadio form={form} field="program_type" option="vpparo" title="v parodontologii (VPPARO)" />
					</div>

					<div className="col-md-6">
						<FormRadio form={form} field="program_type" option="vppedo" title="v pedostomatologii (VPPEDO)" />
					</div>
				</div>
			</CheckBoxRadioFrame>
			<p>
				Místo konání profesních zkoušek: <strong>ČSK, Vzdělávací středisko, Slavojova 22, 128 00 Praha 2</strong>.<br />
				Termín zkoušky Vám Kancelář Komory přidělí s ohledem na aktuální nabídku dostupných termínů.
			</p>

			<h2 className="form-title">Čestné prohlášení žadatele</h2>
			<FormCheckBox
				form={form}
				field="confirmed_declaration"
				className="web-request-repeated-exam-in-vp__declaration"
				enhancedTitle={
					<>
						Čestně prohlašuji, že všechny údaje, které jsem uvedl/a v této žádosti, jsou pravdivé a úplné.
						<br />
						Čestně prohlašuji, že jsem členem Komory, že jsem způsobilý/á k samostatnému výkonu povolání zubního
						lékaře, že mi nebylo pravomocně uloženo disciplinární opatření anebo že takové disciplinární opatření
						již bylo zahlazeno, že jsem nebyl/a pravomocně odsouzen/a za trestný čin spáchaný v souvislosti s výkonem
						povolání zubního lékaře anebo že se na mě hledí, jako bych nebyl/a odsouzen/a, a že nemám vůči Komoře
						nedoplatek na stanovených příspěvcích či poplatcích nebo na pokutě uložené pravomocným rozhodnutím
						v disciplinárním řízení.
					</>
				} />
			<FormCheckBox form={form} field="confirmed_children_care" className="mb-5" />

			{form.getField("confirmed_children_care").value &&
				<div className="mb-5">
					{
						model.formChildren.get().map((i, order) =>
							<div key={order} className="row">
								<div className="col-md-2">
									<FormText>Doba péče o {order + 1}. dítě</FormText>
								</div>
								<div className="col-md-3">
									<FormInput form={i} field="care_from" inputProps={{ type: "date" }} />
								</div>
								<div className="col-md-3">
									<FormInput form={i} field="care_to" inputProps={{ type: "date" }} />

								</div>
								<div className="col-md-4">
									{order !== 0 &&
										<Button className="mr-3 mb-3" onClick={_ => model.formChildren.remove(i)}>Odebrat</Button>
									}

									{order + 1 === model.formChildren.get().length &&
										<Button onClick={model.formChildren.add} className="mb-3">Přidat</Button>
									}
								</div>
							</div>
						)
					}
					<p>
						<strong>Poučení o péči o dítě:</strong><br />
						<em>V rámci vstupního vzdělávacího programu v {model.form.getField("program_type").value == "vppzl" ? "praktickém zubním lékařství" : model.getProgramTypeName()} se do lhůt
							pro přihlášení k profesní zkoušce a pro absolvování předepsaných vzdělávacích akcí
							nezapočítává doba, po kterou člen Komory soustavně osobně pečoval o dítě do 4 let věku.
							Pečují-li o totéž dítě dva členové Komory, považuje se za člena Komory pečujícího o dítě do 4
							let pouze jeden z nich, a to podle jejich volby.
						</em>
					</p>
				</div>
			}
			<p>
				<strong>Poučení</strong><br />
				{model.form.getField("program_type").value == "vppzl" &&
					<>
						<small>
							K opakované profesní zkoušce se můžete přihlásit, pokud jste<br />
							<ol className="--letter mb-3">
								<li><strong>potvrdil/a čestné prohlášení (čestné prohlášení bude nutné v den konání zkoušky potvrdit, aby bylo zřejmé, že stále platí)</strong>,</li>
								<li>ve lhůtě nejvýše 5 let před podáním této žádosti absolvoval/a všechny předepsané vzdělávací akce,</li>
								<li>zaplatil/a poplatek za zpracování této žádosti.</li>
								Do pětileté lhůty uvedené výše se nezapočítává doba, po kterou jste soustavně osobně pečoval/a o dítě do 4 let věku. Tuto dobu prokážete čestným prohlášením.

							</ol>
							U opakované profesní zkoušky budete potřebovat doklad totožnosti a dále<br />

							<ol className="--letter">
								<li>kompletní zdravotnickou dokumentaci 3 pacientů s kariézním nebo špatně ošetřeným chrupem obsahující u každého pacienta nejméně záznamy o vstupním komplexním vyšetření včetně intraorálních rtg snímků a ortopantomogramů, navazující léčebné plány, záznamy o léčebném postupu a záznamy o kontrole (včetně kontrolních rtg snímků) pacienta po 1 roce,</li>
								<li>kompletní zdravotnickou dokumentaci 3 pacientů obsahující u každého pacienta kompletní dokumentaci endodontického ošetření a na něj navazující postendodontické péče,</li>
								<li>kompletní zdravotnickou dokumentaci 3 pacientů obsahující u každého pacienta záznamy o vyšetření a diagnóze parodontopatií, včetně léčebného plánu a záznamů o léčebném postupu,</li>
								<li>kompletní zdravotnickou dokumentaci 2 pacientů se zhotovenou fixní náhradou, obsahující u každého pacienta modely před preparací pilířových zubů, modely po preparaci pilířových zubů, včetně registrace mezičelistních vztahů, případně doplněné otisky a fotodokumentací výsledné práce,</li>
								<li>kompletní zdravotnickou dokumentaci 2 pacientů se zhotovenou částečnou snímatelnou náhradou s litými kotevními prvky nebo se skeletovou konstrukcí, obsahující u každého pacienta studijní modely obou čelistí před provedením protetické práce včetně registrace mezičelistních vztahů (okluzní registrát, spojené skusové šablony, skusová šablona), případně doplněné fotodokumentaci výsledné práce,</li>
								<li>kompletní zdravotnickou dokumentaci 1 pacienta obsahující záznamy o alespoň 1 chirurgickém ošetření pacienta, včetně rtg snímku před výkonem,</li>
								<li>kompletní zdravotnickou dokumentaci 1 dětského pacienta se smíšeným chrupem, obsahující záznamy o vyšetření dokumentované rtg snímky, léčebný plán a záznamy o léčebném postupu.</li>
							</ol>
							<p>
								Bez podkladů uvedených v bodech a) až g) není možno úspěšně absolvovat ústní zkoušku. Zdravotnická dokumentace musí být upravena tak, aby z ní nebylo možno zjistit jméno,
								příjmení, datum narození ani adresu pacienta ani jiných osob. Zdravotnická dokumentace a všechny související materiály (modely, rtg snímky atp.) musí být označeny rokem
								narození pacienta a jakýmkoliv symbolem nahrazujícím jeho jméno a příjmení (např. iniciály) tak, aby bylo zřejmé, že se týkají jednoho pacienta.
							</p>
							<p>
								Opakovaná profesní zkouška se skládá ze znalostního testu a z ústní zkoušky. Obě části se budou konat v jeden den. Pokud jste v minulosti v rámci probíhajícího vzdělávacího
								programu úspěšně absolvoval/a znalostní test, bude se Vaše opakovaná profesní zkouška skládat jen z ústní zkoušky.
							</p>
							<p>
								Znalostní test obsahuje 20 otázek, na které odpovíte výběrem ze 4 variant odpovědí. Mezi variantami bude vždy právě jedna správná. Otázky vycházejí z obsahu předepsaných
								a doporučených vzdělávacích akcí zahrnutých do vstupního vzdělávacího programu v praktickém zubním lékařství. Časový limit pro zodpovězení všech otázek znalostního testu je
								20 minut. Znalostní test nelze přerušit a není při něm dovoleno používat literaturu ani jiné zdroje informací. K úspěšnému absolvování znalostního testu je třeba správně zodpovědět
								alespoň 15 z 20 položených otázek.
							</p>
							<p>
								Pokud ve znalostním testu neuspějete, k ústní zkoušce nepostoupíte a bude to znamenat, že jste celou první opakovanou profesní zkoušku absolvoval/a s hodnocením neprospěl/a.
								V takovém případě budete mít možnost požádat o vykonání druhé opakované profesní zkoušky, a to nejdříve po uplynutí 1 měsíce od konání první opakované profesní zkoušky,
								při které jste neuspěl/a, a nejpozději 2 roky od podání žádosti o vykonání profesní zkoušky. Pokud byste však neuspěl/a při své druhé opakované profesní zkoušce, další opakování
								profesní zkoušky již nebude možné.
							</p>
							<p>
								Pokud úspěšně absolvujete znalostní test anebo pokud znalostní test není součástí Vaší opakované profesní zkoušky, postoupíte k ústní zkoušce. Zde na základě otázek členů
								zkušební komise zdůvodníte diagnostické a léčebné postupy, které jste v průběhu vzdělávacího programu osobně uskutečnil/a. Členové zkušební komise vycházejí při formulaci
								odborných otázek zejména z předložené zdravotnické dokumentace a z výsledků znalostního testu; otázky se však mohou týkat i dalších odborných témat. V případě, že předložená
								zdravotnická dokumentace nebude splňovat požadavky uvedené výše pod písmeny a) až g), bude profesní zkouška hodnocena výsledkem „neprospěl“.
							</p>
							<p>
								Pokud u ústní zkoušky neuspějete, bude to znamenat, že jste celou opakovanou profesní zkoušku absolvoval/a s hodnocením neprospěl/a. V takovém případě budete mít možnost
								požádat o vykonání druhé opakované profesní zkoušky, a to nejdříve po uplynutí 1 měsíce od konání první opakované profesní zkoušky, při které jste neuspěl/a, a nejpozději 2 roky od
								podání žádosti o vykonání profesní zkoušky. Úspěšně absolvovaný znalostní test již nebudete muset v rámci opakované profesní zkoušky znovu absolvovat.
							</p>
							<p>
								Profesní zkoušku lze opakovat nejvýše dvakrát. Pokud neuspějete ani u druhé opakované profesní zkoušky, bude to znamenat, že se Vám nepodařilo absolvovat vstupní vzdělávací
								program. V takovém případě budete mít možnost se znovu zařadit do vstupního vzdělávacího programu a absolvovat jej od začátku.
							</p>
							<p>
								<strong>Za zpracování této žádosti je stanoven poplatek</strong> ve výši <strong>{model.getAmountRepeat()} Kč</strong>.
								Pokyn k úhradě poplatku Vám bude odeslán e-mailem obratem po odeslání Vaší žádosti. Vaše
								žádost bude zpracována po připsání platby na účet ČSK.
							</p>
						</small>
					</>
				}
				{model.form.getField("program_type").value != "vppzl" &&
					<small>
						<p>K opakované profesní zkoušce se můžete přihlásit, pokud jste</p>
						<ol className="--letter mb-3">
							<li>podepsal/a čestné prohlášení v části III této žádosti (čestné prohlášení bude nutné v den konání zkoušky potvrdit, aby bylo zřejmé, že stále platí),</li>
							<li>ve lhůtě nejvýše 5 let před podáním této žádosti absolvoval/a všechny předepsané vzdělávací akce a všechny předepsané stáže,</li>
							<li>zaplatil/a poplatek za zpracování této žádosti.</li>
						</ol>
						<p>
							Do pětileté lhůty uvedené výše se nezapočítává doba, po kterou jste soustavně osobně pečoval/a o dítě do 4 let věku. Tuto dobu prokážete čestným prohlášením.
						</p>
						<p>
							Opakovaná profesní zkouška se skládá ze znalostního testu a z ústní zkoušky. Obě části se budou konat v jeden den. Pokud jste v minulosti v rámci probíhajícího vzdělávacího programu úspěšně absolvoval/a znalostní test, bude se Vaše opakovaná profesní zkouška skládat jen z ústní zkoušky.
						</p>
						<p>
							Znalostní test obsahuje 20 otázek, na které odpovíte výběrem ze 4 variant odpovědí. Mezi variantami bude vždy právě jedna správná. Otázky vycházejí z obsahu předepsaných a doporučených vzdělávacích akcí zahrnutých do vstupního vzdělávacího programu ve {model.getProgramTypeName()}. Časový limit pro zodpovězení všech otázek znalostního testu je 20 minut. Znalostní test nelze přerušit a není při něm dovoleno používat literaturu ani jiné zdroje informací. K úspěšnému absolvování znalostního testu je třeba správně zodpovědět alespoň 15 z 20 položených otázek.
						</p>
						<p>
							Pokud ve znalostním testu neuspějete, k ústní zkoušce nepostoupíte a bude to znamenat, že jste celou první opakovanou profesní zkoušku absolvoval/a s hodnocením neprospěl/a. V takovém případě budete mít možnost požádat o vykonání druhé opakované profesní zkoušky, a to nejdříve po uplynutí 1 měsíce od konání první opakované profesní zkoušky, při které jste neuspěl/a, a nejpozději 2 roky od podání žádosti o vykonání profesní zkoušky. Pokud byste však neuspěl/a při své druhé opakované profesní zkoušce, další opakování profesní zkoušky již nebude možné.
						</p>
						<p>
							Pokud úspěšně absolvujete znalostní test anebo pokud znalostní test není součástí Vaší opakované profesní zkoušky, postoupíte k ústní zkoušce. U ústní části profesní zkoušky samostatně představíte a v diskusi se členy zkušební komise budete obhajovat 3 případy poskytnutí zdravotní péče ve {model.getProgramTypeName()}, které jste v průběhu vstupního vzdělávacího programu osobně poskytl/a. Každý jednotlivý představovaný případ je třeba doložit kompletní zdravotnickou dokumentací, kterou vedete o pacientovi, jemuž byla předmětná zdravotní péče poskytnuta. Zdravotnická dokumentace včetně všech souvisejících materiálů (rtg snímky atp.) musí být upravena tak, aby z ní nebylo možno zjistit jméno, příjmení, datum narození ani adresu pacienta ani jiných osob. Zdravotnická dokumentace a všechny související materiály (rtg snímky atp.) musí být označeny rokem narození pacienta a jakýmkoliv symbolem nahrazujícím jeho jméno a příjmení (např. iniciály) tak, aby bylo zřejmé, že se týkají jednoho pacienta. V případě, že představované případy nebudou doloženy zdravotnickou dokumentací, bude profesní zkouška hodnocena výsledkem „neprospěl“.
						</p>
						<p>
							Pokud u ústní zkoušky neuspějete, bude to znamenat, že jste celou opakovanou profesní zkoušku absolvoval/a s hodnocením neprospěl/a. V takovém případě budete mít možnost požádat o vykonání druhé opakované profesní zkoušky, a to nejdříve po uplynutí 1 měsíce od konání první opakované profesní zkoušky, při které jste neuspěl/a, a nejpozději 2 roky od podání žádosti o vykonání profesní zkoušky. Úspěšně absolvovaný znalostní test již nebudete muset v rámci opakované profesní zkoušky znovu absolvovat.
						</p>
						<p>
							Profesní zkoušku lze opakovat nejvýše dvakrát. Pokud neuspějete ani u druhé opakované profesní zkoušky, bude to znamenat, že se Vám nepodařilo absolvovat vstupní vzdělávací program. V takovém případě budete mít možnost se znovu zařadit do vstupního vzdělávacího programu a absolvovat jej od začátku.
						</p>
						<p>
							<strong>Za zpracování této žádosti je stanoven poplatek</strong> ve výši <strong>{model.getAmountRepeat()} Kč</strong>.
							Pokyn k úhradě poplatku Vám bude odeslán e-mailem obratem po odeslání Vaší žádosti. Vaše
							žádost bude zpracována po připsání platby na účet ČSK.
						</p>
					</small>
				}
			</p>
			{!props.id &&
				<div className="text-right">
					<div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
					<em>Odesláním potvrzuji správnost údajů.</em><br /><br />
					<Button theme="primary" onClick={model.confirm} spinner={model.common.justSending()}>Odeslat</Button>
				</div>
			}

		</WebRequest >
	);
}

export default state.bindContainers(
	WebRequestExamInVpRepeatedPage,
	c => c.webRequestExamInVp
);